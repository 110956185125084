#founder{
  scroll-margin-top: 100px;

}
.founder_bg{
    border-radius: 50%;
    top: 250%;
}
.container h1{
    font-weight: 700;
    font-size: 4rem;
}
.founder{
    background-color: #F6FFFD;
    height: 500px;
}

h2{
    font-size: 4rem;
    font-weight: 700;
}